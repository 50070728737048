import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useFormValidation } from '../../../../hooks/useFormValidation'
import { ConfigurationsActionType } from '../../../../types/configurations'
import { inputValidationCheck } from '../../../../validators/inputValidators/inputValidators'
import { buildControlsExt } from '../../../inputs/controls'
import { inputCall } from '../../control'
import { checkUnsavedChanges } from '../../../../store/actions/configurationActions'

interface IRowAdding {
    path: string[]
    data: any
    schema: any
    setDictKeys?: any
    actionAddDictValue?: (item: Record<string, string>) => void
    isAdding: boolean
    onCancelAdd: () => void
}

export const RowAdding: FC<IRowAdding> = ({
    path,
    data,
    schema,
    setDictKeys,
    actionAddDictValue,
    isAdding,
    onCancelAdd,
}) => {
    const dispatch = useDispatch()
    const initialState = { key: '', value: '' }
    const keySchema = inputValidationCheck(schema.key.type, {
        ...schema.key,
        is_required: true,
    })
    const valueSchema = inputValidationCheck(schema.value.type, {
        ...schema.value,
        is_required: true,
    })

    const validationCheck = { key: keySchema, value: valueSchema }
    const [inputState, setInputState, touched, setTouched, validationResult] =
        useFormValidation(initialState, validationCheck)

    const newItemForm = buildControlsExt(
        [
            inputCall({
                type: schema.key.type,
                name: 'key',
                isLabelHidden: true,
                schema: schema.key,
                isFeedbackFixed: true,
                className: 'app-dictionary-row-input',
                state: inputState,
            }),
            inputCall({
                type: schema.value.type,
                name: 'value',
                isLabelHidden: true,
                schema: schema.value,
                isFeedbackFixed: true,
                className: 'ml-2 app-dictionary-row-input',
                state: inputState,
            }),
        ],
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    const isNewRowValid =
        !validationResult.key &&
        !validationResult.value &&
        inputState.key &&
        inputState.value

    const cleanForm = (): void => {
        setInputState(initialState)
        setTouched({})
    }

    const onCancelHandle = (): void => {
        onCancelAdd()
        cleanForm()
    }

    const onAddDiction = (): void => {
        const item = { [String(inputState.key)]: inputState.value }

        if (isNewRowValid) {
            if (actionAddDictValue) {
                actionAddDictValue(item)
            } else {
                dispatch({
                    type: ConfigurationsActionType.BLOCK_DICTIONARY_ADD,
                    payload: {
                        path,
                        data,
                        item,
                    },
                })
                dispatch(checkUnsavedChanges(path[0]))
            }
            onCancelAdd()
            cleanForm()
            setDictKeys((prev: string[]) => [
                // @ts-ignore
                ...new Set([...prev, inputState.key]),
            ])
        }
    }

    return isAdding ? (
        <tr className="app-bg-primary">
            <td
                className="pt-4"
                style={{ verticalAlign: 'top' }}
            >
                #
            </td>
            {newItemForm.map(
                (el: any, i) =>
                    el?.key !== 'element_id' && (
                        <td
                            style={{
                                verticalAlign: 'top',
                            }}
                            key={i}
                        >
                            {el}
                        </td>
                    )
            )}
            <td style={{ verticalAlign: 'top' }}>
                <div className="d-flex gap-2 justify-content-center">
                    <Button
                        onClick={onAddDiction}
                        size="sm"
                        className="ms-1 my-1 app-btn-add app-dictionary-row"
                        disabled={!isNewRowValid}
                    >
                        <FormattedMessage id="buttons.add" />
                    </Button>
                    <Button
                        onClick={onCancelHandle}
                        variant="outline-secondary"
                        size="sm"
                        className="ms-1 my-1 app-dictionary-row"
                    >
                        <FormattedMessage id="buttons.cancel" />
                    </Button>
                </div>
            </td>
        </tr>
    ) : (
        <></>
    )
}
