import { ModelTypes } from '../../../models/types'
import React, { useState } from 'react'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { modifyActionParameter } from '../../../../store/actions/actionsActions'
import { WrappedBlockACTN } from '../BlockACTN/WrappedBlockACTN'
import { useInitialEffect } from '../../../../hooks/useInitialEffect'
import PromptBtn from '../../../buttons/PromptBtn/PromptBtn'
import AppButton from '../../../buttons/AppButton/AppButton'
import { BlockDictionaryTable } from '../../../models/BlockDictionary/components/BlockDictionaryTable'

interface BlockDictionaryACTNProps {
    name: string
    data: any
    schema: any
    dataPath: string[]
    wrapper?: string
}

export const BlockDictionaryACTN = (props: BlockDictionaryACTNProps) => {
    const { name, data, schema, dataPath, wrapper } = props
    const { is_required: isRequired, is_empty_as_null: isEmptyAsNull } = schema
    const dispatch = useAppDispatch()
    const path = [...(dataPath || []), name]
    const [dictKeys, setDictKeys] = useState(Object.keys(data || {}))
    const [isAddingNewDict, setIsAddingNewDict] = useState(false)

    const dataLen = Object.keys(data ?? {}).length

    const onAddHandle = () => {
        setIsAddingNewDict(true)
    }

    const handleAddDictValue = (dataItem: Record<string, string>) => {
        dispatch(
            modifyActionParameter({
                path,
                data: structuredClone({ [name]: { ...data, ...dataItem } }),
            })
        )
    }

    useInitialEffect(() => {
        if (isRequired && !data) {
            dispatch(modifyActionParameter({ path, data: { [name]: {} } }))
        }
    })

    return (
        <WrappedBlockACTN
            key={path + name}
            data={data}
            dataSchema={schema}
            title={name}
            name={name}
            buttons={[
                <>
                    {isRequired && isEmptyAsNull && !dataLen && (
                        <PromptBtn
                            className="me-2 mt-1"
                            type="validation"
                            prompt="At least 1 item required"
                        />
                    )}
                </>,
                <AppButton
                    variant="add"
                    onClick={onAddHandle}
                    disabled={isAddingNewDict}
                    className="btn btn-add p-1 m-1 ms-auto me-3 ps-3 pe-3"
                    tooltip="tooltips.addNewRow"
                />,
            ]}
            elements={[
                <BlockDictionaryTable
                    data={data}
                    dataPath={dataPath!}
                    dataSchema={schema}
                    name={name}
                    path={path}
                    dictKeys={dictKeys}
                    setDictKeys={setDictKeys}
                    isAdding={isAddingNewDict}
                    setIsAdding={setIsAddingNewDict}
                    actionAddDictValue={handleAddDictValue}
                />,
            ]}
            wrapper={wrapper || ModelTypes.BLOCK_DICTIONARY}
            dataPath={dataPath}
        />
    )
}
