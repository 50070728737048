import { alertErrorMessage } from '../../utils/Errors'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import axios from 'axios'
import { getIsPrevUser } from '../../utils/checkPrevUser'
import { RoutesPaths } from '../../router'
import {
    getJwt,
    getTokenRefresh,
    saveJwt,
    saveUser,
    tokenRefresh,
} from '../../utils/authentication'

export const fetchSignIn = async (
    login: string,
    password: string,
    onSuccess: any
): Promise<void> => {
    try {
        const { data } = await axios.post(`/api/oauth/authorize`, {
            login,
            password,
        })
        saveJwt(data.access_token)
        tokenRefresh(data.refresh_token)
        saveUser(data)
        onSuccess()
        getIsPrevUser(login)
    } catch (e: any) {
        alertErrorMessage(e)
    }
}

export const renewTokens = async () => {
    try {
        const { data: tokens } = await axios.post<{
            access_token: string
            refresh_token: string
        }>(RoutesPaths.REFRESH_TOKEN, {
            refresh_token: getTokenRefresh(),
        })
        if (tokens.access_token && tokens.refresh_token) {
            saveJwt(tokens.access_token)
            tokenRefresh(tokens.refresh_token)
            saveUser(tokens)
        }
        return tokens
    } catch (e: any) {
        alertErrorMessage(e)
    }
}

const refreshAuth = async (failedRequest: any): Promise<void> => {
    const tokens = await renewTokens()
    if (tokens?.access_token) {
        failedRequest.response.config.headers['Authorization'] =
            `Bearer ${tokens.access_token}`
    }
}

createAuthRefreshInterceptor(axios, refreshAuth, { statusCodes: [401] })

axios.interceptors.request.use((request: any) => {
    request.headers['Authorization'] = `Bearer ${getJwt()}`
    return request
})