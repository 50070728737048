import { IActionHistory } from '../../../types/actionsTypes'
import styles from './ActionHistoryRow.module.scss'
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileZipper } from '@fortawesome/free-regular-svg-icons'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import {
    cancelAction,
    downloadActionResult,
} from '../../../store/actions/actionsActions'
import { convertToSentenceCase } from '../../../utils/convertToSentenceCase'
import AppButton from '../../buttons/AppButton/AppButton'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { selectOneProductUtcOffset } from '../../../store/selectors/productsUtcOffsetSelectors'
import { getProductTime } from '../../../utils/getProductTime'

interface ActionHistoryRowProps {
    className?: string
    actionHistoryItem: IActionHistory
}

export const ActionHistoryRow = (props: ActionHistoryRowProps) => {
    const dispatch = useAppDispatch()
    const { actionHistoryItem } = props
    const {
        product_id: productId,
        result_type: resultType,
        action_id: actionId,
        action_name: actionName,
        issue_time: issueTime,
        status,
        result,
        message,
    } = actionHistoryItem

    const productUtcOffset = useTypedSelector(
        selectOneProductUtcOffset(productId)
    )
    const productIssueTime = getProductTime(productUtcOffset, issueTime)
    const timeOnActionStart =
        productUtcOffset && productIssueTime ? productIssueTime : ''
    const issueTimeView =
        productUtcOffset && productIssueTime ? productIssueTime : issueTime

    const filename = result?.filename
    const actionStatus = capitalizeFirstLetter(actionHistoryItem.status)
    const actionNameModified = convertToSentenceCase(actionName)
    const handleFileDownload = () => dispatch(downloadActionResult(actionId))
    const handleCancelAction = () => dispatch(cancelAction(actionId))

    return (
        <tr
            key={actionId}
            className={styles.tableRow}
        >
            {
                <td className={styles.tableCell}>
                    {status === 'ongoing' ? timeOnActionStart : issueTimeView}
                </td>
            }
            <td className={styles.tableCell}>{actionNameModified}</td>
            <td className={`${styles.tableCell} ${styles[status]}`}>
                {actionStatus}
            </td>
            <td className={styles.tableCell}>
                {resultType === 'file' && !!filename && (
                    <span className="d-flex align-items-center">
                        <button
                            onClick={handleFileDownload}
                            className={`${styles.downloadBtn} btn-clean d-flex align-items-center gap-1`}
                        >
                            <FontAwesomeIcon
                                icon={faFileZipper}
                                className="align-self-start pt-1"
                            />
                            <span className="d-inline">{filename}</span>
                        </button>
                    </span>
                )}
                {(status === 'failure' ||
                    status === 'canceled' ||
                    status === 'success') &&
                    !!message && <span>{message}</span>}
            </td>
            {status === 'ongoing' && (
                <td>
                    <AppButton
                        variant="close"
                        tooltip="Cancel action"
                        onClick={handleCancelAction}
                    />
                </td>
            )}
        </tr>
    )
}
