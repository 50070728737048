import { getAuthPermissions, getAuthUsername } from '../utils/authentication'

export enum UserPermissions {
    USER_ADMINISTRATION = 'user_administration',
    USER_MANAGEMENT = 'user_management',
    GROUP_MANAGEMENT = 'group_management',
    CONFIGURATION_ADMINISTRATION = 'configuration_administration',
    CONFIGURATION_MANAGEMENT = 'configuration_management',
    CONFIGURATION_READ = 'configuration_read',
}

export enum UserRoles {
    OWNER = 'Owner',
    ADMINISTRATOR = 'Administrator',
    FULL_RIGHTS_MANAGER = 'Full-rights manager',
    WL_MANAGER = 'WL manager',
    VIEWER = 'Viewer',
    NOT_STANDARD_ROLE = 'Non-standard role',
}

export const AllProductsAccessTypes: any = {
    Administrator: UserRoles.ADMINISTRATOR,
    Owner: UserRoles.FULL_RIGHTS_MANAGER,
}

export interface IUser {
    login: string
    permissions: UserPermissions[]
}

export interface IUserBackend extends IUser {
    visibility: {
        products: string[]
        groups: string[]
        all_products: boolean
    }
}

export interface IUserFrontend extends IUserBackend {
    password?: string
}

export class User implements IUser {
    public login: string
    public permissions: UserPermissions[]

    constructor(login?: string, permissions?: UserPermissions[]) {
        this.login = login ?? getAuthUsername()
        this.permissions = permissions ?? getAuthPermissions()
    }

    public static isOwner(user: IUser) {
        return user.permissions.includes(UserPermissions.USER_ADMINISTRATION)
    }

    isOwner() {
        return User.isOwner(this)
    }

    public static isAdministrator(user: IUser) {
        return (
            user.permissions.includes(UserPermissions.USER_MANAGEMENT) &&
            !user.permissions.includes(UserPermissions.USER_ADMINISTRATION)
        )
    }

    isAdministrator() {
        return User.isAdministrator(this)
    }

    public static isFullRightsManager(user: IUser) {
        return (
            user.permissions.includes(
                UserPermissions.CONFIGURATION_ADMINISTRATION
            ) && !user.permissions.includes(UserPermissions.USER_MANAGEMENT)
        )
    }

    isFullRightsManager() {
        return User.isFullRightsManager(this)
    }

    public static isWl(user: IUser) {
        return (
            user.permissions.includes(
                UserPermissions.CONFIGURATION_MANAGEMENT
            ) &&
            !user.permissions.includes(
                UserPermissions.CONFIGURATION_ADMINISTRATION
            )
        )
    }

    isWl() {
        return User.isWl(this)
    }

    public static isViewer(user: IUser) {
        return (
            user.permissions.includes(UserPermissions.CONFIGURATION_READ) &&
            user.permissions.length === 1
        )
    }

    isViewer() {
        return User.isViewer(this)
    }

    public static isAdministratorOrOwner(user: IUser) {
        return (
            user.permissions.includes(UserPermissions.USER_MANAGEMENT) &&
            user.permissions.includes(UserPermissions.GROUP_MANAGEMENT)
        )
    }

    isAdministratorOrOwner() {
        return User.isAdministratorOrOwner(this)
    }

    public static isFullRightsManagerOrAbove(user: IUser) {
        return user.permissions.includes(
            UserPermissions.CONFIGURATION_ADMINISTRATION
        )
    }

    isFullRightsManagerOrAbove() {
        return User.isFullRightsManagerOrAbove(this)
    }

    public static isWlOrAbove(user: IUser) {
        return user.permissions.includes(
            UserPermissions.CONFIGURATION_MANAGEMENT
        )
    }

    isWlOrAbove() {
        return User.isWlOrAbove(this)
    }

    public static getRole(user: IUser) {
        if (User.isOwner(user)) return UserRoles.OWNER
        if (User.isAdministrator(user)) return UserRoles.ADMINISTRATOR
        if (User.isFullRightsManager(user)) return UserRoles.FULL_RIGHTS_MANAGER
        if (User.isWl(user)) return UserRoles.WL_MANAGER
        if (User.isViewer(user)) return UserRoles.VIEWER
        return UserRoles.NOT_STANDARD_ROLE
    }

    getRole() {
        return User.getRole(this)
    }

    public static createEmptyVisibility() {
        return {
            products: [],
            groups: [],
            all_products: false,
        }
    }
}
