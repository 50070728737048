import { useDispatch } from 'react-redux'
import React from 'react'
import { inputValidationCheck } from '../../../../validators/inputValidators/inputValidators'
import { useFormValidation } from '../../../../hooks/useFormValidation'
import { buildControlsExt } from '../../../inputs/controls'
import { inputCall } from '../../control'
import { ConfigurationsActionType } from '../../../../types/configurations'
import { checkUnsavedChanges } from '../../../../store/actions/configurationActions'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface DictionaryValueEditProps {
    path: string[]
    dictionaryKey: string
    dictionaryValue: string
    schema: any
    hideEditForm: () => void
    actionAddDictValue?: (item: Record<string, string>) => void
}

export const DictionaryValueEdit = (props: DictionaryValueEditProps) => {
    const {
        path,
        schema,
        dictionaryKey,
        dictionaryValue,
        hideEditForm,
        actionAddDictValue,
    } = props
    const dispatch = useDispatch()

    const valueSchema = inputValidationCheck(schema.value.type, {
        ...schema.value,
        is_required: true,
    })
    const validationCheck = { value: valueSchema }
    const initialState = { value: dictionaryValue ?? '' }

    const [inputState, setInputState, touched, setTouched, validationResult] =
        useFormValidation(initialState, validationCheck)

    const editForm = buildControlsExt(
        [
            inputCall({
                type: schema.value.type,
                name: 'value',
                isLabelHidden: true,
                schema: schema.value,
                isFeedbackFixed: true,
                className: 'app-dictionary-row-input',
                state: inputState,
            }),
        ],
        inputState,
        setInputState,
        '',
        touched,
        setTouched,
        validationResult
    )

    const isEditValueValid = !validationResult.value && inputState.value

    const cleanForm = () => {
        setInputState(initialState)
        setTouched({})
    }

    const onCancelHandle = () => {
        hideEditForm()
        cleanForm()
    }

    const handleChangeValueSubmit = () => {
        const item = { [dictionaryKey ?? '']: inputState.value }

        if (isEditValueValid) {
            if (actionAddDictValue) {
                actionAddDictValue(item)
            } else {
                dispatch({
                    type: ConfigurationsActionType.BLOCK_DICTIONARY_EDIT_DICT_VALUE,
                    payload: {
                        path,
                        key: dictionaryKey ?? '',
                        value: inputState.value,
                    },
                })
            }
            dispatch(checkUnsavedChanges(path[0]))
            hideEditForm()
            cleanForm()
        }
    }

    return (
        <tr style={{ display: 'table-row' }}>
            <td />
            <td className="pt-4">{dictionaryKey}</td>
            <td className="d-flex">{editForm}</td>
            <td>
                <Button
                    onClick={handleChangeValueSubmit}
                    variant="outline-primary"
                    size="sm"
                    className="ms-1 my-1 app-btn-add app-dictionary-row"
                    disabled={!isEditValueValid}
                >
                    <FormattedMessage id="buttons.apply" />
                </Button>
                <Button
                    onClick={onCancelHandle}
                    variant="outline-secondary"
                    size="sm"
                    className="ms-1 my-1 app-dictionary-row"
                >
                    <FormattedMessage id="buttons.cancel" />
                </Button>
            </td>
        </tr>
    )
}
