import { toast } from 'react-toastify'

export const RECONNECTING_SIGNALR = {
    show: 'show',
    hide: 'hide',
} as const

const toastId = 'RECONNECTING_SIGNALR_TOAST_ID'
const toastIdProductStatuses = 'TOAST_ID_PRODUCT_STATUSES'

export const showReconnectingMessage = (
    type: keyof typeof RECONNECTING_SIGNALR
) => {
    switch (type) {
        case RECONNECTING_SIGNALR.show: {
            toast(
                'Lost live connection to the server. Trying to reconnect...',
                {
                    toastId,
                    type: 'error',
                    position: 'bottom-right',
                    icon: false,
                    autoClose: false,
                    closeButton: false,
                    style: {
                        backgroundColor: '#E95050',
                        borderRadius: '4px',
                        border: '1px solid #C14242',
                        color: '#fff',
                    },
                }
            )
            toast('Cannot display actual statuses of products', {
                toastId: toastIdProductStatuses,
                type: 'error',
                position: 'bottom-right',
                icon: false,
                autoClose: false,
                closeButton: false,
                style: {
                    backgroundColor: '#E95050',
                    borderRadius: '4px',
                    border: '1px solid #C14242',
                    color: '#fff',
                },
            })
            return
        }

        case RECONNECTING_SIGNALR.hide: {
            toast.dismiss(toastId)
            toast.dismiss(toastIdProductStatuses)
            toast('Live connection restored', {
                type: 'success',
                position: 'bottom-right',
            })
            return
        }
    }
}
