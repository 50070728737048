const userLocale = navigator.language ?? 'en-US'

export const getProductTime = (offset: number, productTime?: string) => {
    if (offset === null || offset === undefined) return

    const date = productTime ? new Date(productTime) : new Date()
    const currentUtcOffsetMilliseconds = date.getTimezoneOffset() * 60 * 1000
    const utcTime = date.getTime() + currentUtcOffsetMilliseconds

    const offsetInMilliseconds = offset * 60 * 1000

    return new Date(utcTime + offsetInMilliseconds).toLocaleString(userLocale)
}
