import React, { FC, useState } from 'react'
import { IBlockDictionaryRow } from '../types'
import { blockDictionaryActions } from '../../../../store/actions/modelsActions/control'
import { useAppDispatch } from '../../../../hooks/useAppDispatch'
import { DictionaryValueEdit } from './DictionaryValueEdit'
import AppButton from '../../../buttons/AppButton/AppButton'
import { showModal } from '../../../../store/actions/modalActions'
import { ModalTypes } from '../../../../types/modals'

const BlockDictionaryRow: FC<IBlockDictionaryRow> = ({
    data = {},
    dataSchema = {},
    name,
    position,
    dataPath,
    dictionaryKey,
    value,
    setDictKeys,
    actionAddDictValue,
}) => {
    const dispatch = useAppDispatch()
    const [path] = useState([...(dataPath || []), name])
    const [isEditing, setIsEditing] = useState(false)

    const hideEditForm = () => setIsEditing(false)
    const showEditForm = () => setIsEditing(true)

    const onDeleteDiction = (): void => {
        dispatch(blockDictionaryActions.deleteItem(path, data, dictionaryKey))
        setDictKeys((prev) => prev.filter((dict) => dict !== dictionaryKey))
    }

    const handleDelete = () =>
        dispatch(
            showModal(ModalTypes.MAIN_MODAL, {
                body: `Delete row ${position}?`,
                onSubmit: onDeleteDiction,
            })
        )

    if (isEditing)
        return (
            <DictionaryValueEdit
                path={path}
                dictionaryKey={dictionaryKey}
                dictionaryValue={value}
                schema={dataSchema}
                hideEditForm={hideEditForm}
                actionAddDictValue={actionAddDictValue}
            />
        )

    return (
        <tr style={{ display: 'table-row' }}>
            <td className={`${isEditing ? 'pt-4' : ''}`}>{position}</td>
            <td>{dictionaryKey}</td>
            <td>{String(value)}</td>
            <td className="d-flex justify-content-center">
                <AppButton
                    variant="edit"
                    onClick={showEditForm}
                    tooltip="tooltips.editRow"
                />
                <AppButton
                    variant="delete"
                    onClick={handleDelete}
                    tooltip="tooltips.deleteRow"
                />
            </td>
        </tr>
    )
}

export default BlockDictionaryRow
