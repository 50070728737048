import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { PrivateRoute } from 't4b-core-frontend'
import { ProductStatus } from '../../entity/Product'
import { useTypedSelector } from '../../hooks/useTypedSelector'
import { RoutesPaths } from '../../router'
import { maxRequestTime } from '../../store/actions/http'
import { showModal } from '../../store/actions/modalActions'
import { fetchProducts } from '../../store/actions/products/productsActions'
import { ModalTypes } from '../../types/modals'
import { IProduct, ProductActionType } from '../../types/product'
import Loader from '../Loader/Loader'
import { Products } from '../Products/Products'
import { QuickAccess } from '../QuickAccess/QuickAccess'
import { SummaryHeader } from './Components/SummaryHeader'
import { useLocalStorage } from '../../hooks/useLocalStorage'

const Summary: FC = () => {
    const dispatch = useDispatch()
    const location = useLocation()

    const { auth: isAuthenticated } = useSelector((state: any) => state)
    const { products, isLoading } = useTypedSelector((state) => state.products)

    const [isRequestTimeExpired, setIsRequestTimeExpired] = useState(false)
    const [hideInactiveState, setHideInactiveState] = useLocalStorage(
        'hideInactive',
        { showActive: false }
    )

    const inactiveProductsCheck = useMemo(
        () =>
            products.find(
                (product: IProduct) => product.status === ProductStatus.INACTIVE
            ),
        [products]
    )

    const handleHideInactive = useCallback(setHideInactiveState, [
        setHideInactiveState,
    ])

    useEffect(() => {
        dispatch({ type: ProductActionType.SET_PRODUCT_ROUTED, payload: true })
    }, []) // eslint-disable-line

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => setIsRequestTimeExpired(true), maxRequestTime)
        }
    }, [isLoading]) // eslint-disable-line

    useEffect(() => {
        if (isLoading && isRequestTimeExpired) {
            dispatch(
                showModal(ModalTypes.ERROR_MODAL, {
                    request: () => fetchProducts(true),
                    onCancel: 'cancel',
                    title: <FormattedMessage id="errors.server.request" />,
                    message: (
                        <FormattedMessage id="errors.server.requestTimeExceeded" />
                    ),
                })
            )
            setIsRequestTimeExpired(false)
            dispatch({ type: ProductActionType.FETCH_PRODUCTS_CANCEL })
        }
    }, [isRequestTimeExpired]) // eslint-disable-line

    if (isLoading && !products.length) return <Loader />

    if (location.pathname === RoutesPaths.SUMMARY) {
        return <Redirect to={RoutesPaths.PRODUCTS} />
    }

    return (
        <div className="flex-1 pt-4 pb-5">
            <SummaryHeader
                inactiveProductsCheck={!!inactiveProductsCheck}
                hideInactiveState={hideInactiveState}
                onHideInactive={handleHideInactive}
            />
            <OverlayScrollbarsComponent className="vh-full p-4">
                <div className="ps-5 pe-5">
                    <Switch>
                        <PrivateRoute
                            exact
                            path={RoutesPaths.PRODUCTS}
                            isAuthenticated={isAuthenticated}
                            component={
                                <Products
                                    hideInactiveState={hideInactiveState}
                                />
                            }
                        />
                        <PrivateRoute
                            exact
                            path={RoutesPaths.QUICK_ACCESS}
                            isAuthenticated={isAuthenticated}
                            component={
                                <QuickAccess
                                    hideInactiveState={hideInactiveState}
                                />
                            }
                        />
                    </Switch>
                </div>
            </OverlayScrollbarsComponent>
        </div>
    )
}

export default Summary
