import { getLabel } from '../../../../validators'
import PromptBtn from '../../../buttons/PromptBtn/PromptBtn'
import { RowAdding } from './RowAdding'
import BlockDictionaryRow from './BlockDictionaryRow'
import { Dispatch, SetStateAction } from 'react'

interface BlockDictionaryTableProps {
    data: Record<string, any>
    dataPath: string[]
    dataSchema: Record<string, any>
    name: string
    path: string[]
    dictKeys: string[]
    setDictKeys: Dispatch<SetStateAction<string[]>>
    isAdding: boolean
    setIsAdding: Dispatch<SetStateAction<boolean>>
    actionAddDictValue?: (item: Record<string, string>) => void
}

export const BlockDictionaryTable = (props: BlockDictionaryTableProps) => {
    const {
        data,
        dataPath,
        dataSchema,
        name,
        path,
        dictKeys,
        setDictKeys,
        isAdding,
        setIsAdding,
        actionAddDictValue,
    } = props

    const componentSchema = dataSchema?.children
    const keyNames = Object.keys(componentSchema)

    const onCancelAddHandle = () => {
        setIsAdding(false)
    }

    return (
        <table className="m-0 table app-table table-hover p-relative w-100">
            <thead>
                <tr
                    className="border-bottom bg-body py-4"
                    style={{
                        color: '#8dabc4',
                        fontSize: '.7rem',
                        textTransform: 'uppercase',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1000,
                    }}
                >
                    <td>#</td>
                    {keyNames.map((keyName: string) => (
                        <td key={keyName}>
                            {getLabel(
                                componentSchema?.[keyName]?.name || keyName
                            )}{' '}
                            {componentSchema[keyName]?.description && (
                                <PromptBtn
                                    size="small"
                                    className="mt-1"
                                    prompt={
                                        componentSchema[keyName]?.description
                                    }
                                />
                            )}
                        </td>
                    ))}
                    <td>Actions</td>
                </tr>
                <RowAdding
                    setDictKeys={setDictKeys}
                    schema={dataSchema.children}
                    path={path}
                    data={data}
                    isAdding={isAdding}
                    onCancelAdd={onCancelAddHandle}
                    actionAddDictValue={actionAddDictValue}
                />
            </thead>
            <tbody>
                {dictKeys.map((el: string, index: number) => (
                    <BlockDictionaryRow
                        key={el}
                        position={index + 1}
                        dictionaryKey={el}
                        value={data?.[el] ?? ''}
                        data={data}
                        dataSchema={componentSchema}
                        dataPath={dataPath}
                        name={name}
                        setDictKeys={setDictKeys}
                        actionAddDictValue={actionAddDictValue}
                    />
                ))}
            </tbody>
        </table>
    )
}